import { useI18n } from "utils/with-i18n.utils";

import { NavLink } from "../NavLinks/NavLink";

import QuestionMark from "./question-mark.svg";
import UserBlack from "./user-black.svg";

type NavLinkTopProps = {
  onClick?: (href: string) => void;
};

export const NavLinksTop = ({ onClick }: NavLinkTopProps) => {
  const i18n = useI18n();

  return (
    <ul className="flex-row items-center px-6 space-y-5 w-full lg:flex lg:px-0 lg:space-y-0 lg:max-w-fit">
      <li>
        <NavLink
          href="/comment-ca-marche"
          className="lg:pr-5 lg:pl-2"
          onClickLink={onClick}
        >
          <div className="flex gap-1 items-center">
            <QuestionMark />
            {i18n.t("navbar.howItWorks")}
          </div>
        </NavLink>
      </li>

      <li className="items-center lg:flex">
        <NavLink
          href="/business"
          className="lg:pr-5 lg:pl-2"
          onClickLink={onClick}
        >
          <div className="flex gap-1 items-center">
            <UserBlack />
            {i18n.t("navbar.ProBusiness")}
          </div>
        </NavLink>
      </li>
    </ul>
  );
};
