import Link from "next/link";

import { useI18n } from "utils/with-i18n.utils";

import { NavLinkBusiness } from "./NavLinkBusiness";

export const NavLinksBusiness = () => {
  const i18n = useI18n();

  return (
    <>
      <Link href="/business" passHref>
        <NavLinkBusiness className="lg:pl-[18px]">
          {i18n.t("navbar.business.business")}
        </NavLinkBusiness>
      </Link>

      <Link href="/business/gestion-de-flotte" passHref>
        <NavLinkBusiness className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.fleetManagement")}
        </NavLinkBusiness>
      </Link>

      <Link href="/business/catalogue" passHref>
        <NavLinkBusiness className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.ourDevices")}
        </NavLinkBusiness>
      </Link>

      <Link href="/" passHref>
        <NavLinkBusiness className="lg:border-[3px] lg:border-transparent">
          {i18n.t("navbar.business.privateOffer")}
        </NavLinkBusiness>
      </Link>
    </>
  );
};
