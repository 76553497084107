import cx from "classnames";
import Link from "next/link";
import React, { HTMLProps } from "react";

import LinkArrowSVG from "./link-arrow.svg";

type NavLinkProps = HTMLProps<HTMLAnchorElement> & {
  onClickLink?: (href: string) => void;
};

export const NavLink = React.forwardRef(
  ({ children, className, onClickLink, ...props }: NavLinkProps, _ref) => {
    return (
      <Link href={props.href as string}>
        <a
          onClick={() => onClickLink?.(props.href as string)}
          className={cx(
            "flex items-center font-medium text-base w-full lg:max-w-fit",
            className,
          )}
          {...props}
        >
          <div className="flex justify-between items-center w-full lg:block">
            <span> {children}</span>
            <LinkArrowSVG className="lg:hidden" />
          </div>
        </a>
      </Link>
    );
  },
);
