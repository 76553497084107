import cx from "classnames";
import React, { HTMLProps } from "react";

type NavLinkProps = HTMLProps<HTMLAnchorElement>;

export const NavLinkBusiness = React.forwardRef(
  ({ children, className, ...props }: NavLinkProps, _ref) => {
    return (
      <a
        className={cx(
          "flex items-center space-between text-2xl font-medium lg:text-lg text-white",
          className,
        )}
        {...props}
      >
        {children}
      </a>
    );
  },
);
