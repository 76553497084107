import { Maybe } from "graphql";
import { useRouter } from "next/router";

import { Button } from "ui/Button";
import { useLogout } from "utils/useLogout";
import { useI18n } from "utils/with-i18n.utils";

type MobileLoginButtonProps = { customerId: Maybe<number> };

export const MobileLoginButton = ({ customerId }: MobileLoginButtonProps) => {
  const i18n = useI18n();

  const { push } = useRouter();

  const logout = useLogout();

  const handleGoToLogin = () => push("/login");

  if (customerId) {
    return (
      <Button className=" w-3/4  " alternative="enabled" onClick={logout}>
        {i18n.t("navbar.logout")}
      </Button>
    );
  }

  return (
    <Button className="w-3/4" alternative="secondary" onClick={handleGoToLogin}>
      {i18n.t("navbar.customerAccount")}
    </Button>
  );
};
