import { useRouter } from "next/router";
import { HTMLAttributes, useEffect, useState } from "react";
import { match } from "ts-pattern";

import { Alternative, Button, Shadow } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

import { LoggedUserIcon } from "../../../LoggedUserIcon";

import ResumeOrderSVG from "./resume-order.svg";
import UserSVG from "./user.svg";

type LoginButtonProps = HTMLAttributes<HTMLButtonElement> & {
  customerName?: string;
  hasSession: boolean;
  hasIncompleteOrder: boolean;
};

export const LoginButton = ({
  customerName,
  hasSession,
  hasIncompleteOrder,
  className,
  ...props
}: LoginButtonProps) => {
  const i18n = useI18n();
  const { push } = useRouter();

  const [alternative, setAlternative] = useState<Alternative>("secondary");

  const handleGoToLogin = () => push("/login");

  const handleGoToAccount = () => push("/espace-membre");

  const handleGoToFunnel = () => push("/commander/recapitulatif");

  useEffect(() => {
    const newAlternative = match<{}, Alternative>({
      hasIncompleteOrder,
      hasSession,
    })
      .with({ hasIncompleteOrder: true }, () => "primary")
      .with({ hasSession: true }, () => "enabled")
      .otherwise(() => "secondary");

    setAlternative(newAlternative);
  }, [hasIncompleteOrder, hasSession]);

  const { handleClick, Icon, locale } = match({
    hasIncompleteOrder,
    hasSession,
  })
    .with({ hasIncompleteOrder: true }, () => ({
      handleClick: handleGoToFunnel,
      Icon: ResumeOrderSVG,
      locale: i18n.t("navbar.resumeOrder"),
    }))
    .with({ hasSession: true }, () => ({
      handleClick: handleGoToAccount,
      Icon: () => <LoggedUserIcon customerName={customerName} />,
      locale: i18n.t("navbar.helloCustomer", { name: customerName }),
    }))
    .otherwise(() => ({
      handleClick: handleGoToLogin,
      Icon: UserSVG,
      locale: i18n.t("navbar.customerAccount"),
    }));

  return (
    <Button
      onClick={handleClick}
      {...props}
      className={className}
      alternative={alternative}
      shadow={Shadow.Black}
      borderOnMobile={true}
      aria-label="login"
    >
      <div className={"flex flex-row items-center"}>
        <Icon />

        <span className={"hidden pl-[10px] lg:block"}>{locale}</span>
      </div>
    </Button>
  );
};
