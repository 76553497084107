import cx from "classnames";

import { BurgerMenu } from "shared/BurgerMenu";
import { BurgerMenuMode } from "shared/BurgerMenu/BurgerMenuIcon";
import { useBurgerMenuActions } from "shared/Navbar/useBurgerMenuActions";
import { useCustomerId } from "utils/use-customer-id.utils";
import { useI18n } from "utils/with-i18n.utils";

import { LoginBlockBusiness } from "./LoginBlockBusiness";
import { ContactUs } from "./LoginBlockBusiness/ContactUs";
import { NavLinksBusiness } from "./NavLinksBusiness";
import { NavLogoBusiness } from "./NavLogoBusiness";

type NavbarProps = { withBanner?: boolean };

export const NavbarBusiness = ({ withBanner }: NavbarProps) => {
  const i18n = useI18n();

  const customerId = useCustomerId();

  const { handleCloseBurgerMenu, handleOpenBurgerMenu, isBurgerMenuOpen } =
    useBurgerMenuActions();

  return (
    <nav
      className={cx(
        isBurgerMenuOpen &&
          "bg-black fixed lg:static lg:h-fit w-full h-screen z-50",
      )}
    >
      <div
        className={
          "flex justify-between items-center px-[18px] pt-[40px] pb-[12px] bg-black lg:py-[18px] lg:px-[32px] lg:text-lg"
        }
      >
        <BurgerMenu
          onOpenBurgerMenu={handleOpenBurgerMenu}
          onCloseBurgerMenu={handleCloseBurgerMenu}
          isBurgerMenuOpen={isBurgerMenuOpen}
          mode={BurgerMenuMode.Dark}
        />

        <NavLogoBusiness />

        <div className={"hidden gap-x-[24px] text-white lg:flex"}>
          <NavLinksBusiness />
        </div>
        <LoginBlockBusiness customerId={customerId} />
      </div>

      {isBurgerMenuOpen && (
        <div
          className={
            "flex z-50 flex-col gap-y-9 items-center py-24 w-full h-screen bg-black border-t lg:hidden"
          }
        >
          <NavLinksBusiness />

          <ContactUs isBurgerMenuOpen={isBurgerMenuOpen} />
        </div>
      )}
    </nav>
  );
};
