import cx from "classnames";
import { ReactNode } from "react";

import { useSettings } from "shared/SettingsProvider/useSettings";

type BannerNavProps = {
  content: ReactNode;
};

export const BannerNav = ({ content }: BannerNavProps) => {
  const { isBlackFriday } = useSettings();

  return (
    <div
      className={cx(
        "flex items-center justify-center text-center min-h-[2rem] text-xs lg:text-sm border-b-[1px] lg:border-b-0",
        isBlackFriday ? "bg-black border-y-white" : "bg-yellow border-y-black",
      )}
    >
      {content}
    </div>
  );
};
