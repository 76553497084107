import { BurgerMenuIcon, BurgerMenuMode } from "./BurgerMenuIcon";

type BurgerMenuProps = {
  onCloseBurgerMenu: () => void;
  onOpenBurgerMenu: () => void;
  isBurgerMenuOpen: boolean;
  mode: BurgerMenuMode;
};

export const BurgerMenu = ({
  onCloseBurgerMenu,
  onOpenBurgerMenu,
  isBurgerMenuOpen,
  mode,
}: BurgerMenuProps) => {
  return (
    <div className="flex items-center lg:hidden">
      <button
        className="flex justify-between lg:hidden"
        onClick={isBurgerMenuOpen ? onCloseBurgerMenu : onOpenBurgerMenu}
        aria-label="menu"
      >
        <BurgerMenuIcon isBurgerMenuOpen={isBurgerMenuOpen} mode={mode} />
      </button>
    </div>
  );
};
