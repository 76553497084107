import cx from "classnames";

import { BannerNav } from "ui/BannerNav";
import { useCustomerId } from "utils/use-customer-id.utils";
import { useI18n } from "utils/with-i18n.utils";

import { BurgerMenu } from "../BurgerMenu";
import { BurgerMenuMode } from "../BurgerMenu/BurgerMenuIcon";

import { LoginBlock } from "./LoginBlock";
import { MobileLoginButton } from "./MobileLoginButton";
import { NavLinks } from "./NavLinks";
import { NavLinksTop } from "./NavLinksTop";
import { NavLogo } from "./NavLogo";
import { useBurgerMenuActions } from "./useBurgerMenuActions";

type NavbarProps = {
  withBanner?: boolean;
};

export const Navbar = ({ withBanner }: NavbarProps) => {
  const i18n = useI18n();

  const customerId = useCustomerId();

  const {
    handleCloseBurgerMenu,
    handleOpenBurgerMenu,
    isBurgerMenuOpen,
    handleLinkClick,
  } = useBurgerMenuActions();

  return (
    <nav
      className={cx(
        isBurgerMenuOpen &&
          "fixed lg:static lg:h-fit w-full h-screen z-50 bg-lotion",
      )}
    >
      <div className="hidden lg:block">
        {withBanner && (
          <BannerNav content={i18n.t("banner.content", {}, { html: true })} />
        )}
      </div>
      <div
        className={
          "flex justify-between items-center py-5 px-4 lg:px-6 lg:mt-0 lg:text-lg"
        }
      >
        <BurgerMenu
          onOpenBurgerMenu={handleOpenBurgerMenu}
          onCloseBurgerMenu={handleCloseBurgerMenu}
          isBurgerMenuOpen={isBurgerMenuOpen}
          mode={BurgerMenuMode.Light}
        />

        <NavLogo />

        <div className="flex">
          <div className="hidden items-center pr-3 lg:flex">
            <NavLinksTop />
          </div>

          <LoginBlock customerId={customerId} />
        </div>
      </div>
      <div className="block lg:hidden">
        {withBanner && (
          <BannerNav
            content={i18n.t("banner.contentMobile", {}, { html: true })}
          />
        )}
      </div>

      <div
        className={cx(
          "hidden lg:flex py-3 justify-center items-center border-y-[1px] border-black gap-x-6",
        )}
      >
        <NavLinks />
      </div>

      {isBurgerMenuOpen && (
        <div
          className={cx(
            "flex z-50 flex-col gap-y-9 items-center py-10 w-full h-screen border-t lg:hidden",
          )}
        >
          <NavLinks onClick={handleLinkClick} />

          <MobileLoginButton customerId={customerId} />

          <NavLinksTop onClick={handleLinkClick} />
        </div>
      )}
    </nav>
  );
};
