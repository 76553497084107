import { Maybe } from "graphql";

import { applyTax } from "utils/price.utils";
import { useLogout } from "utils/useLogout";

import { useGetNavbarCustomerOrderQuery } from "../getNavbarCustomerOrder.generated";
import LogoutSVG from "../logout.svg";

import { LoginButton } from "./LoginButton";
import { OrderPreview } from "./OrderPreview";
import { useRevealPreview } from "./useRevealPreview";

type LoginBlockProps = {
  customerId: Maybe<number>;
};

export const LoginBlock = ({ customerId }: LoginBlockProps) => {
  const logout = useLogout();

  const hasSession = !!customerId;

  const { data: { customer } = {} } = useGetNavbarCustomerOrderQuery({
    variables: { customerId: customerId! },
    skip: !customerId,
  });

  const orders = customer?.orders || [];
  const device = orders?.[0]?.subscription;
  const hasDevice = !!device;
  const subscriptionPrice = applyTax(
    device?.price ?? 0,
    device?.item.tax?.amount,
  );

  const { handleShowPreview, handleHidePreview, isPreviewOpen } =
    useRevealPreview(hasDevice);

  const incompleteOrders = orders.filter(
    ({ state }) =>
      state && (state.name === "draft" || state.name === "created"),
  );

  const completeOrders = orders.filter(
    ({ state }) => state && state.name !== "draft" && state.name !== "created",
  );

  const hasIncompleteOrder =
    !completeOrders.length && !!incompleteOrders.length;

  return (
    <div className="flex relative gap-x-5 items-center">
      <LoginButton
        hasSession={hasSession}
        customerName={customer?.firstName || ""}
        hasIncompleteOrder={hasIncompleteOrder}
        onMouseEnter={handleShowPreview}
        onMouseLeave={handleHidePreview}
        className="py-2 px-[0.5rem] lg:px-6"
      />

      {hasSession && (
        <button onClick={logout} className="hidden lg:block">
          <LogoutSVG />
        </button>
      )}

      {hasDevice && isPreviewOpen && hasIncompleteOrder && (
        <OrderPreview
          color={device.item.properties?.color}
          handleShowPreview={handleShowPreview}
          handleHidePreview={handleHidePreview}
          model={device.item.properties?.model}
          price={subscriptionPrice}
          storage={device.item.properties?.storage}
          sku={device.item.sku}
        />
      )}
    </div>
  );
};
