import Image from "next/image";
import Link from "next/link";

export const NavLogo = () => {
  return (
    <Link href={"/"}>
      <a aria-label="return to home" className="flex items-center">
        <div className="relative w-[200px] h-[21.6px] lg:w-[240px] lg:h-[26px]">
          <Image
            src={"/images/logo/logo-default.svg"}
            layout="fill"
            alt="mobile club logo"
          />
        </div>
      </a>
    </Link>
  );
};
