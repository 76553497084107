import cx from "classnames";
import { useRouter } from "next/router";
import { HTMLAttributes } from "react";
import { match } from "ts-pattern";

import { LoggedUserIcon } from "shared/LoggedUserIcon";
import UserWhiteSVG from "shared/Navbar/LoginBlock/LoginButton/user-white.svg";
import { Alternative, Button, Shadow } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

type LoginButtonProps = HTMLAttributes<HTMLButtonElement> & {
  customerName?: string;
  hasSession: boolean;
};

export const LoginButtonBusiness = ({
  customerName,
  hasSession,
  className,
  ...props
}: LoginButtonProps) => {
  const i18n = useI18n();
  const { push } = useRouter();

  const alternative: Alternative = hasSession ? "enabled" : "transparent";

  const handleGoToAccount = () => {
    push("/espace-membre");
  };

  const handleGoToBusinessLogin = () => push("/business/login");

  const { handleClick, Icon, locale } = match({
    hasSession,
  })
    .with({ hasSession: true }, () => ({
      handleClick: handleGoToAccount,
      Icon: () => <LoggedUserIcon customerName={customerName} />,
      locale: i18n.t("navbar.helloCustomer", { name: customerName }),
    }))
    .otherwise(() => ({
      handleClick: handleGoToBusinessLogin,
      Icon: UserWhiteSVG,
      locale: i18n.t("navbar.business.logIn"),
    }));

  return (
    <Button
      onClick={handleClick}
      {...props}
      className={className}
      alternative={alternative}
      shadow={Shadow.None}
      borderOnMobile={false}
      aria-label="login"
    >
      <div className={cx("flex flex-row items-centergap-x-2")}>
        <Icon />

        <span className={cx("hidden lg:block font-medium pl-[10px]")}>
          {locale}
        </span>
      </div>
    </Button>
  );
};
