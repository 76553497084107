import { useRouter } from "next/router";
import { useState, useEffect } from "react";

export const useBurgerMenuActions = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const handleOpenBurgerMenu = () => {
    setCurrentPath("");
    setBurgerMenuOpen(true);
  };

  const handleCloseBurgerMenu = () => setBurgerMenuOpen(false);

  const { asPath } = useRouter();

  // Close burger menu when route changes
  useEffect(() => {
    handleCloseBurgerMenu();
  }, [asPath]);

  // Close burger menu if same route
  const handleLinkClick = (href: string) => {
    if (asPath === href) {
      handleCloseBurgerMenu();
    }
  };

  return {
    handleLinkClick,
    handleCloseBurgerMenu,
    handleOpenBurgerMenu,
    isBurgerMenuOpen,
  };
};
